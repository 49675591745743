export const getters = {
  form: 'form',
  errors: 'errors',

  lotName: 'lotName',
  notesLocalized: 'notesLocalized',
}

export const actions = {
  CREATE_LOT: 'CREATE_LOT',
  VALIDATE_FORM: 'VALIDATE_FORM',
  RESET_FORM: 'RESET_FORM',
  SET_LOCATION: 'SET_LOCATION',
}

export const mutations = {
  RESET_STATE: 'RESET_STATE',

  SET_FORM_FIELD: 'SET_FORM_FIELD',
  SET_ERROR: 'SET_ERROR',

  SET_IS_REGISTERED: 'SET_IS_REGISTERED',
  SET_IS_ON_ROAD: 'SET_IS_ON_ROAD',
}
