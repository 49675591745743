import { mutations as mutationTypes } from './types'

export default {
  [mutationTypes.SET_PHOTOS] (state, photos) {
    state.photos = photos
  },
  [mutationTypes.PUSH_UPLOAD_QUEUE] (state, items) {
    state.uploadQueue.push(...items)
  },
  [mutationTypes.SHIFT_UPLOAD_QUEUE] (state) {
    state.uploadQueue.shift()
  },
  [mutationTypes.PUSH_THUMBNAILS_QUEUE] (state, items) {
    state.thumbnailsQueue.push(...items)
  },
  [mutationTypes.SHIFT_THUMBNAILS_QUEUE] (state) {
    state.thumbnailsQueue.shift()
  },
  [mutationTypes.SET_IS_QUEUE_UPLOADING] (state, value) {
    state.isQueueUploading = value
  },
}
