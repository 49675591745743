export const actions = {
  UPLOAD_PHOTOS: 'UPLOAD_PHOTOS',
  PUSH_PHOTOS: 'PUSH_PHOTOS',
  UPLOAD_PHOTO: 'UPLOAD_PHOTO',
  PUSH_PHOTO: 'PUSH_PHOTO',
  SET_PHOTOS: 'SET_PHOTOS',
  UPDATE_PHOTO: 'UPDATE_PHOTO',
  REMOVE_PHOTO: 'REMOVE_PHOTO',
  REMOVE_ALL_PHOTOS: 'REMOVE_ALL_PHOTOS'
}

export const mutations = {
  SET_PHOTOS: 'SET_PHOTOS',
  PUSH_UPLOAD_QUEUE: 'PUSH_UPLOAD_QUEUE',
  SHIFT_UPLOAD_QUEUE: 'SHIFT_UPLOAD_QUEUE',
  PUSH_THUMBNAILS_QUEUE: 'PUSH_THUMBNAILS_QUEUE',
  SHIFT_THUMBNAILS_QUEUE: 'SHIFT_THUMBNAILS_QUEUE',
  SET_IS_QUEUE_UPLOADING: 'SET_IS_QUEUE_UPLOADING',
}

export const getters = {
  photos: 'photos',
  uploadQueue: 'uploadQueue',
  thumbnailsQueue: 'thumbnailsQueue',
  isQueueUploading: 'isQueueUploading',
  isAllPhotosUploaded: 'isAllPhotosUploaded',
  showAddPhotoButton: 'showAddPhotoButton',
}
