import formSchema from '../../../json-schemas/validation'

export const photoUploadingStatuses = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR',
  TOO_BIG: 'TOO_BIG',
  INVALID_FORMAT: 'INVALID_FORMAT'
}

export const MAX_PHOTOS_COUNT = formSchema.properties.fullImgLinks.maxItems
export const MAX_PHOTO_SIZE = 10000000 // 10 MB in bytes

export const THUMBNAIL_WIDTH = 640 // px
export const THUMBNAIL_HEIGHT = 480 // px
export const THUMBNAIL_JPEG_QUALITY = 80
