import { sdk } from 'Services/shelfNetworkSdk'
import {
  actions as actionTypes,
  getters as getterTypes,
  mutations as mutationTypes
} from './types'
import { actions as photosActions } from './modules/photos/types'

import { DEFAULT_LOCALE } from 'Constants/locales'
import { AUCTION_TYPES, DEFAULT_UPLOAD_LANG } from './constants'

import { jsv } from 'Utils/jsv'
import validationSchema from '../json-schemas/validation'

import { unixTimestamp } from 'Utils/dateHelpers'
import { Location } from 'Models/Location'

export default {
  async [actionTypes.RESET_FORM] ({ commit, dispatch }) {
    dispatch(`photos/${photosActions.REMOVE_ALL_PHOTOS}`)
    commit(mutationTypes.RESET_STATE)
  },
  async [actionTypes.VALIDATE_FORM] ({ commit, getters }) {
    const result = jsv.validate(validationSchema, getters.form)

    for (const [field, errors] of Object.entries(result.byField())) {
      if (errors.length) {
        commit(mutationTypes.SET_ERROR, { field, value: errors[0] })
      }
    }

    return result.isValid
  },

  async [actionTypes.SET_LOCATION] ({ commit }, cityId) {
    if (!cityId) {
      commit(mutationTypes.SET_FORM_FIELD, { field: 'country', value: '' })
      commit(mutationTypes.SET_FORM_FIELD, { field: 'city', value: '' })
      commit(mutationTypes.SET_FORM_FIELD, { field: 'cityId', value: '' })
      return
    }

    try {
      const { data: locations } = await sdk.identity.locations.getByPlatform({
        lang: DEFAULT_UPLOAD_LANG,
        query: { filter: { 'city.id': cityId } }
      })

      if (locations.length === 0) return

      const location = new Location(locations[0])

      commit(mutationTypes.SET_FORM_FIELD, {
        field: 'country',
        value: location.countryAlpha3Code
      })
      commit(mutationTypes.SET_FORM_FIELD, {
        field: 'city',
        value: location.cityName
      })
      commit(mutationTypes.SET_FORM_FIELD, {
        field: 'cityId',
        value: String(cityId)
      })
    } catch (err) {
      console.error(err)
    }
  },

  async [actionTypes.CREATE_LOT] ({ getters }) {
    const form = getters[getterTypes.form]

    const attributes = {
      startTime: 0,
      type: form.auctionType,
      currency: form.currency,

      details: {
        city: form.city,
        city_id: form.cityId,
        color: form.color,
        country: form.country,
        engine_volume: form.engineCapacity,
        fuel_type: form.fuelType,
        full_img_links: form.fullImgLinks,
        hd_img_links: form.hdImgLinks,
        lot_name: getters[getterTypes.lotName],
        maker: form.maker,
        model: form.model,
        notes:
          getters[getterTypes.notesLocalized][DEFAULT_LOCALE.toLowerCase()],
        notes_localized: JSON.stringify(getters[getterTypes.notesLocalized]),
        odometer_value: /mi/i.test(form.mileageUnits)
          ? String(Math.ceil(form.mileage * 1.60934)) // to km
          : String(form.mileage),
        odometer_origin_value: String(form.mileage),
        odometer_origin_units: form.mileageUnits.toLowerCase(),
        registered: form.isRegistered,
        transmission: form.transmission,
        vin_code: form.vin,
        wheel: form.wheel,
        year: Number(form.firstRegistrationYear),
        youtube_link: form.youtubeLink,
      },
    }

    switch (form.auctionType) {
      case AUCTION_TYPES.sale:
        attributes.b_to_c_vehicle_price = form.saleB2CPrice
        attributes.buy_now_price = form.saleB2CPrice

        attributes.b_to_b_vehicle_price = form.saleB2BPrice || form.saleB2CPrice
        attributes.b_to_c_total_price =
          form.saleB2CTotalPrice ||
          form.saleB2CPrice

        attributes.deposit = '0'
        attributes.min_step = '0'
        attributes.start_price = '0'

        attributes.duration = Number(form.saleDuration) * 3600 * 24
        attributes.details.on_road = form.isOnRoad
        attributes.details.price_negotiable = form.isPriceNegotiable
        break
      case AUCTION_TYPES.eng:
        attributes.deposit = form.engDeposit
        attributes.min_step = form.engMinStep
        attributes.max_step = form.engMaxStep

        const now = unixTimestamp()
        attributes.duration = unixTimestamp(new Date(form.engEndTime)) - now

        attributes.start_price = form.engStartPrice
        attributes.buy_now_price = form.engBuyNowPrice || '0'
        attributes.buy_now_support = Boolean(form.engBuyNowPrice)
        break
      default:
        throw new Error(`unsupported auction type: ${form.auctionType}`)
    }

    await sdk.horizon.lots.create(attributes)
  },
}
