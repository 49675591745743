import { getters as getterTypes } from './types'
import { translateEnum } from 'Utils/translateEnum'
import { enumsGetters } from 'Store/entities/Enums/types'
import { rootGet } from 'Store/helpers/rootHelpers'

export default {
  [getterTypes.form]: state => state.form,
  [getterTypes.errors]: state => state.errors,
  [getterTypes.notesLocalized]: state => Object.entries(state.form)
    .filter(([key]) => /^notesLocalized\/\w+/i.test(key))
    .reduce((res, [key, value]) => {
      const locale = key.split('/')[1]
      res[locale] = value
      return res
    }, {}),

  [getterTypes.lotName]: (state, getters) => {
    const form = getters[getterTypes.form]

    const maker = translateEnum({
      name: 'MAKER',
      value: form.maker,
      translations: rootGet(`entities/enums/${enumsGetters.TRANSLATIONS}`),
    })
    const model = translateEnum({
      name: 'MODEL',
      value: form.model,
      translations: rootGet(`entities/enums/${enumsGetters.TRANSLATIONS}`),
    })
    const year = form.firstRegistrationYear

    return `${year} ${maker} ${model}`
  },
}
