import { photoUploadingStatuses, MAX_PHOTOS_COUNT } from './constants'
import { getters as getterTypes } from './types'

export default {
  [getterTypes.photos]: state => state.photos,
  [getterTypes.uploadQueue]: state => state.uploadQueue,
  [getterTypes.thumbnailsQueue]: state => state.thumbnailsQueue,
  [getterTypes.isQueueUploading]: state => state.isQueueUploading,

  [getterTypes.isAllPhotosUploaded]: (state, getters) =>
    getters[getterTypes.photos].every(
      photo => photo.status !== photoUploadingStatuses.PENDING,
    ),

  [getterTypes.showAddPhotoButton]: state =>
    state.photos.length < MAX_PHOTOS_COUNT,
}
