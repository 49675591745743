import {
  photoUploadingStatuses,
  MAX_PHOTOS_COUNT,
  MAX_PHOTO_SIZE,
  THUMBNAIL_WIDTH,
  THUMBNAIL_HEIGHT,
  THUMBNAIL_JPEG_QUALITY,
} from './constants'
import {
  actions as actionTypes,
  mutations as mutationTypes,
  getters as getterTypes,
} from './types'

import { userGetters } from 'Store/entities/User/types'
import lowerCase from 'lodash/lowerCase'
import cropImage from 'Utils/cropImage'
import { sdk } from 'Services/shelfNetworkSdk'

export default {
  async [actionTypes.UPLOAD_PHOTOS] ({ getters, dispatch, commit }, photos) {
    dispatch(actionTypes.PUSH_PHOTOS, photos)

    while (getters[getterTypes.thumbnailsQueue].length) {
      const photo = getters[getterTypes.photos]
        .find(p => p.id === getters[getterTypes.thumbnailsQueue][0])
      const thumbnail = await cropImage(photo.file, {
        width: THUMBNAIL_WIDTH,
        height: THUMBNAIL_HEIGHT,
        jpegQuality: THUMBNAIL_JPEG_QUALITY,
      })
      dispatch(actionTypes.UPDATE_PHOTO, {
        ...photo,
        thumbnail,
        localThumbnailUrl: URL.createObjectURL(thumbnail),
      })
      commit(mutationTypes.SHIFT_THUMBNAILS_QUEUE)
    }

    if (getters[getterTypes.isQueueUploading]) return

    commit(mutationTypes.SET_IS_QUEUE_UPLOADING, true)
    while (getters[getterTypes.uploadQueue].length) {
      const photo = getters[getterTypes.photos]
        .find(p => p.id === getters[getterTypes.uploadQueue][0])
      await dispatch(actionTypes.UPLOAD_PHOTO, photo)
      commit(mutationTypes.SHIFT_UPLOAD_QUEUE)
    }
    commit(mutationTypes.SET_IS_QUEUE_UPLOADING, false)
  },

  [actionTypes.PUSH_PHOTOS] ({ commit, getters, dispatch }, files) {
    const photosToPushCount =
      MAX_PHOTOS_COUNT - getters[getterTypes.photos].length
    const photosToPush = files.slice(0, photosToPushCount)
      .map(file => {
        const id = Symbol('photo')
        const localUrl = URL.createObjectURL(file)

        let status
        if (file.size > MAX_PHOTO_SIZE) {
          status = photoUploadingStatuses.TOO_BIG
        } else {
          status = isImage(file)
            ? photoUploadingStatuses.PENDING
            : photoUploadingStatuses.INVALID_FORMAT
        }

        return { id, localUrl, status, file }
      })

    photosToPush.forEach(photo => {
      dispatch(actionTypes.PUSH_PHOTO, photo)
    })

    const uploadQueue = photosToPush
      .filter(p => p.status === photoUploadingStatuses.PENDING)
      .map(p => p.id)
    commit(mutationTypes.PUSH_UPLOAD_QUEUE, uploadQueue)
    commit(mutationTypes.PUSH_THUMBNAILS_QUEUE, uploadQueue)
  },

  async [actionTypes.UPLOAD_PHOTO] ({ dispatch, rootGetters }, photo) {
    const owners = []
    const accountId = rootGetters[`app/user/${userGetters.accountId}`]
    if (accountId) {
      owners.push(accountId)
    }

    try {
      const upload = async photo => {
        const response = await sdk.files.upload(photo, {
          isPublic: true,
          owners,
        })

        return response.data.s3Link
      }

      const [url, thumbnailUrl] = await Promise.all([
        upload(photo.file),
        upload(photo.thumbnail),
      ])

      dispatch(actionTypes.UPDATE_PHOTO, {
        ...photo,
        url,
        thumbnailUrl,
        status: photoUploadingStatuses.COMPLETE,
      })
    } catch (e) {
      console.error(e)
      dispatch(actionTypes.UPDATE_PHOTO, {
        ...photo,
        status: photoUploadingStatuses.ERROR,
      })
    }
  },

  [actionTypes.PUSH_PHOTO] ({ dispatch, getters }, photo) {
    dispatch(actionTypes.SET_PHOTOS, [...getters[getterTypes.photos], photo])
  },

  [actionTypes.SET_PHOTOS] ({ commit }, photos) {
    commit(mutationTypes.SET_PHOTOS, photos)
  },

  [actionTypes.UPDATE_PHOTO] ({ dispatch, getters }, photo) {
    const photos = getters[getterTypes.photos]
    const indexToUpdate = photos.findIndex(p => p.id === photo.id)

    if (indexToUpdate === -1) {
      return
    }

    photos.splice(indexToUpdate, 1, photo)
    dispatch(actionTypes.SET_PHOTOS, photos)
  },

  [actionTypes.REMOVE_PHOTO] ({ dispatch, getters }, id) {
    dispatch(
      actionTypes.SET_PHOTOS,
      getters[getterTypes.photos].filter(p => p.id !== id),
    )
  },

  [actionTypes.REMOVE_ALL_PHOTOS] ({ dispatch }) {
    dispatch(actionTypes.SET_PHOTOS, [])
  }
}

function isImage (photo) {
  return ['png', 'jpg', 'jpeg']
    .reduce((isImage, extension) =>
      isImage || lowerCase(photo.name).endsWith(extension), false)
}
