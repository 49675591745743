import { config } from '@/config'
import { LOCALES } from 'Constants/locales'

export function genAddCarState () {
  return {
    form: {
      auctionType: '',
      currency: config.DEFAULT_CURRENCY,
      maker: '',
      model: '',
      firstRegistrationYear: '',
      engineCapacity: '',
      fuelType: '',
      mileage: '',
      mileageUnits: 'km',
      transmission: '',
      color: '',
      wheel: '',
      vin: '',
      fullImgLinks: [],
      hdImgLinks: [],
      ...(Object.values(LOCALES))
        .reduce((res, { isoCode }) => ({
          ...res, [`notesLocalized/${isoCode}`]: ''
        }), {}),
      youtubeLink: '',
      city: '',
      cityId: '',
      country: '',
      isOnRoad: false,
      isRegistered: true,
      isPriceNegotiable: false,
      saleB2BPrice: '',
      saleB2CPrice: '',
      saleB2CTotalPrice: '',
      saleDuration: '',
      engDeposit: '',
      engMinStep: '',
      engMaxStep: '',
      engEndTime: '',
      engStartPrice: '',
      engBuyNowPrice: '',
    },
    errors: {}
  }
}

export default genAddCarState
